import React from "react";

const Todolist = () => {
    return(
        <div> 
            <h1>Schulische Bahn:</h1>
            <br></br>
            <h3>2009.09/2019.07: "bis zur 10. Klasse in Portugal besucht"</h3>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h3>2019.10/2021.07: "Berufsbildende Schule 2 (Gastronomie)"</h3>
            <br></br>
            <h4> Gotheplatz 7, 30169 Hannover</h4>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h3>2021.09/bis jetzt: "Berufsbildende Schule Metalltechnik/Elektrotechnik"</h3>
            <br></br>
            <h4> Gotheplatz 7, 30169 Hannover </h4>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
           

        </div>
    );
};
export default Todolist;